const colors = {
  green: {
    '50': '#f7fcf9',
    '100': '#efefef',
    '200': '#efefef',
    '300': '#000000',
    '400': '#000000',
    '500': '#37a169',
    '600': '#000000',
    '700': '#000000',
    '800': '#000000',
    '900': '#000000',
  },
  blue: {
    '50': '#f0ece5',
    '100': '#978c7a',
    '200': '#57401c',
    '300': '#57401c',
    '400': '#353535',
    '500': '#333333',
    '600': '#56401c',
    '700': '#000000',
    '800': '#000000',
    '900': '#000000',
  },
  red: {
    '500': '#E53E3E',
    '100': '#FED7D7',
  },
  orange: {
    '50': '#fffaf0', // <-
    '100': '#e3e3e3',
    '200': '#cfcfcf',
    '300': '#cacaca',
    '400': '#bcbcbc',
    '500': '#1e1e1e',
    '600': '#1e1e1e',
    '700': '#1e1e1e',
    '800': '#1e1e1e',
    '900': '#1e1e1e',
  },
  gray: {
    '50': '#f0ece5', // <-
    '100': '#e1dfdb',
    '200': '#e1dfdb',
    '300': '#cac6bf',
    '400': '#b2ada3',
    '500': '#7f7f7f',
    '600': '#000000',
    '700': '#000000',
    '800': '#000000',
    '900': '#000000',
  },
  black: '#3c3c3c',
  white: '#f8f6f3',
  blackAlpha: {
    '50': 'RGBA(16, 17, 18, 0.04)',
    '100': 'RGBA(16, 17, 18, 0.06)',
    '200': 'RGBA(16, 17, 18, 0.08)',
    '300': 'RGBA(16, 17, 18, 0.16)',
    '400': 'RGBA(16, 17, 18, 0.24)',
    '500': 'RGBA(16, 17, 18, 0.36)',
    '600': '#000000',
    '700': '#000000',
    '800': '#000000',
    '900': '#000000',
  },
  github: '#171923',
  telegram: '#2775CA',
  linkedin: '#1564BA',
  discord: '#9747FF',
  slack: '#1BA27A',
  twitter: '#000000',
  opensea: '#2081E2',
  facebook: '#4460A0',
  medium: '#231F20',
  reddit: '#FF4500',
};

export default colors;
