import type { Unit } from 'types/unit';

import config from 'configs/app';

const weiName = config.chain.currency.weiName || 'aKAON(wei)';

export const currencyUnits: Record<Unit, string> = {
  wei: weiName,
  gwei: `nKAON`,
  ether: config.chain.currency.symbol || 'KAON',
  akaon: 'aKAON',
  nkaon: 'nKAON',
  kaon: 'KAON',
};
